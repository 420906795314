<script>
import { mapActions } from 'vuex'
import { required, requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'ManagementCertificatesEdit',
  components: {
    Action: () => import('@/components/general/Action'),
    ActionBar: () => import('@/components/general/ActionBar'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    FormSection: () => import('@/components/general/FormSection'),
    InputField: () => import('@/components/general/InputField'),
    Modal: () => import('@/components/general/Modal'),
    SelectField: () => import('@/components/general/SelectField'),
    Upload: () => import('@/components/general/Upload'),
    TextareaField: () => import('@/components/general/TextareaField'),
    RichTextEditor: () => import('@/components/general/RichTextEditor'),
    Radio: () => import('@/components/general/Radio')
  },
  data () {
    return {
      backUrl: { name: 'management.certificates' },
      certificateImagesType: 'F',
      formData: {
        id: null,
        name: null,
        html: null,
        imageFront: null,
        imageBack: null
      },
      backAndFrontOptions: [
        { label: this.$t('global:image.front'), value: 'F' },
        { label: this.$t('global:image.back.and.front'), value: 'FV' }
      ],
      tagInfoList: [
        { text: this.$t('global:form.management.certificate.edit:tag.info.student'), value: 'student' },
        { text: this.$t('global:form.management.certificate.edit:tag.info.solution.title'), value: 'solutionTitle' },
        { text: this.$t('global:form.management.certificate.edit:tag.info.solution.description'), value: 'solutionDescription' },
        { text: this.$t('global:form.management.certificate.edit:tag.info.workload'), value: 'workload' },
        { text: this.$t('global:form.management.certificate.edit:tag.info.initial'), value: 'initial' },
        { text: this.$t('global:form.management.certificate.edit:tag.info.conclusion'), value: 'conclusion' },
        { text: this.$t('global:form.management.certificate.edit:tag.info.session'), value: 'session' },
        { text: this.$t('global:form.management.certificate.edit:tag.info.url_hash'), value: 'url_hash' }
      ],
      modalActive: false,
      formChanged: false
    }
  },
  validations: {
    formData: {
      name: { required },
      html: { required },
      imageFront: { required },
      imageBack: {
        required: requiredIf(function () {
          return this.certificateImagesType === 'FV'
        })
      }
    }
  },
  computed: {
    isEditing () {
      return this.$route.params.id && this.$route.params.isNew !== 'new'
    }
  },
  watch: {
    'certificateImagesType' () {
      this.$v.formData.imageBack.$reset()
    },
    'formData.html' () {
      if (this.$v.formData.html.$dirty) {
        this.formChanged = true
      }
    },
    'formData.imageFront' () {
      if (this.$v.formData.imageFront.$dirty) {
        this.formChanged = true
      }
    },
    'formData.imageBack' () {
      if (this.$v.formData.imageBack.$dirty) {
        this.formChanged = true
      }
    }
  },
  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptSaveCertificate',
      'attemptGetCertificateManagement',
      'attemptUpdateCertificate',
      'attemptGetPreviewCertificate'
    ]),
    leave () {
      this.modalConfirm = false
      this.$nextTick(() => {
        this.$router.push(this.backUrl)
      })
    },
    submitCreation () {
      this.setFetching(true)
      this.attemptSaveCertificate(this.formatFormData()).then(() => {
        this.setFeedback({ message: this.$t('management.certificate:feedback.created.success') })
        this.$router.push({ name: 'management.certificates' })
      }).catch(({ response }) => {
        this.setFeedback({ message: this.$t('management.certificate:feedback.created.error') })
      }).finally(() => {
        this.setFetching(false)
      })
    },
    submitUpdate () {
      this.setFetching(true)
      this.attemptUpdateCertificate(this.formatFormData()).then(() => {
        this.setFeedback({ message: this.$t('management.certificate:feedback.updated.success') })
        this.formChanged = false
      }).catch(({ response }) => {
        this.setFeedback({ message: this.$t('management.certificate:feedback.updated.error') })
      }).finally(() => {
        this.setFetching(false)
      })
    },
    submit (next) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$route.params.id ? this.submitUpdate(next) : this.submitCreation(next)
      } else {
        this.setFeedback({ message: this.$t('management.certificate:feedback.validation.error') })
      }
    },
    formatFormData () {
      const data = {}
      if (this.$route.params.id) {
        data.id = this.$route.params.id
      }
      data.name = this.formData.name
      data.template = this.formData.html
      data.pages = this.certificateImagesType
      data.image_front = this.formData.imageFront
      data.image_back = this.certificateImagesType === 'FV' ? this.formData.imageBack : null
      return data
    },
    preview () {
      this.setFetching(true)
      if (this.$route.params.id) {
        this.attemptGetPreviewCertificate(this.$route.params.id).then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'Certificado.pdf'
          link.click()
        }).catch(({ response }) => {
          this.setFeedback({ message: this.$t('global:form.management.certificate.preview.error') })
        }).finally(() => {
          this.setFetching(false)
        })
      }
    },
    addTagToHTML (tag) {
      if (this.formData.html === null) {
        this.formData.html = `<p>{{ ${tag} }}</p>`
      } else {
        this.formData.html = this.formData.html.substring(0, this.formData.html.length - 4) + `{{${tag}}}</p>`
      }
    }
  },
  created () {
    if (this.$route.params.id) {
      this.setFetching(true)
      this.attemptGetCertificateManagement(this.$route.params.id).then((data) => {
        this.formData = {
          id: data.id,
          name: data.name,
          html: data.template,
          imageFront: data.imageFront,
          imageBack: data.imageBack
        }
        this.certificateImagesType = (!!data.imageFront && !!data.imageBack) ? 'FV' : 'F'
      }).finally(() => {
        this.setFetching(false)
      })
    }
  }
}
</script>
<template>
  <div class="main-content management-certificate-create">
    <content-header
      :title="isEditing ? formData.title : $t('management.certificate.edit:header.title')"
      dark-theme
      fixed
    >
      <action
        slot="back"
        type="button"
        :text="$t('global:back.management')"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave()"
      />
      <action-bar slot="actionbar" />
      <template slot="buttons">
        <action
          :text="$t('global:form.save')"
          type="button"
          dark
          flat
          @click="submit(false)"
        />
      </template>
    </content-header>
    <div class="main-content-inner">
      <div class="center">
        <div class="management-certificate-create-header">
          <p
            class="management-certificate-create-description"
            v-html="$t('management.certificate.edit:header.description')"
          />
        </div>
        <form
          class="form"
          @submit.prevent="submit"
        >
          <input-field
            v-model="formData.name"
            :label="$t('global:form.management.certificate.model')"
            :validation="$v.formData.name"
            :counter="100"
          />
          <form-section
            class="no-margin-bottom"
            :title="$t('global:form.management.certificate.background')"
          >
            <radio
              v-model="certificateImagesType"
              :items="backAndFrontOptions"
              horizontal
            />
            <p>{{ $t('global:form.management.certificate.background.size') }}</p>
            <div class="row no-margin">
              <upload
                v-model="formData.imageFront"
                class="col-6"
                :icon="'image-multiple'"
                :label="$t('global:form.management.certificate.background.input')"
                :description="$t('global:form.management.certificate.background.front')"
                cropper
                :width="2245"
                :height="1587"
                :preview="0.10"
                :validation="$v.formData.imageFront"
              />
              <upload
                v-if="certificateImagesType === 'FV'"
                v-model="formData.imageBack"
                class="col-6"
                :icon="'image-multiple'"
                :label="$t('global:form.management.certificate.background.input')"
                :description="$t('global:form.management.certificate.background.back')"
                cropper
                :width="2245"
                :height="1587"
                :preview="0.10"
                :validation="$v.formData.imageBack"
              />
            </div>
          </form-section>
          <form-section
            class="no-margin-top"
            :title="$t('global:form.management.certificate.tags')"
          >
            <p style="margin: 0 0 10px 0; font-size: 12px">
              {{ $t('global:form.management.certificate.tags.info') }}
            </p>
            <ul class="tags-list">
              <li
                v-for="(item, index) in tagInfoList"
                :key="index"
                class="tag-item"
                @click="addTagToHTML(item.value)"
              >
                <span class="tag-item-value">{{ $t(`certificates.tags:${item.value}`) }}</span>
              </li>
            </ul>
          </form-section>
          <form-section :title="$t('certificate:text.editor')">
            <p style="margin: 0 0 10px 0; font-size: 12px">
              {{ $t('global:form.management.certificate.text.info') }}
            </p>
            <rich-text-editor
              v-model="formData.html"
              :validation="$v.formData.html"
            />
          </form-section>
          <form-section
            v-if="isEditing"
            class="form-section-center"
          >
            <action
              type="button"
              primary
              fixed-width
              :text="$t('global:form.management.certificate.preview')"
              @click="preview"
            />
            <p
              v-if="formChanged"
              style="margin: 10px 0 10px 0; font-size: 12px"
            >
              {{ $t('global:form.management.certificate.preview.info') }}
            </p>
          </form-section>
        </form>
      </div>
    </div>
    <modal
      class="management-certificate-modal"
      :active="modalActive"
      @close="modalActive = false"
    >
      <div class="modal-content">
        <iframe id="certificate_preview" />
      </div>
    </modal>
  </div>
</template>
<style src="@/assets/styles/themes/default/management.css"></style>
